import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PHONE_NUMBER_REGEX } from '@core/constants';
import {
  ConfigurationService,
  ContextDataService,
  DateTimeService,
  TenantSettingsService,
  UserService
} from '@core/services';
import { deCapitalizeFirstLetter } from '@core/utils/helper-functions';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { first, forkJoin } from 'rxjs';
import { UserProfileConfigurator } from './user-profile.configurator';
import { UserProfileService } from './user-profile.service';
import { Timezone, UserProfile, UserProfileForm } from './user-profile.types';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  languages: string[] = [];
  timezones: Timezone[] = [];
  errorList: string[] = [];
  submitLoading = false;
  isUserProfileLoaded = false;
  pageConfigurator!: UserProfileConfigurator;
  userProfileForm = this.formBuilder.group<UserProfileForm>({
    language: new FormControl(null),
    timeZoneId: new FormControl(null),
    shouldMailSendForStatusUpdate: new FormControl(null),
    serviceAdvisorNumber: new FormControl(null, [
      Validators.maxLength(5),
      Validators.pattern(this.tenantSettingsService.serviceAdvisorRegexValidation)
    ]),
    salesNumber: new FormControl(null, [
      Validators.maxLength(5),
      Validators.pattern(this.tenantSettingsService.salesNumberRegexValidation)
    ]),
    dmsUserName: new FormControl(null),
    phoneNumber: new FormControl(null, [Validators.maxLength(20), Validators.pattern(PHONE_NUMBER_REGEX)])
  });
  isSelfServiceActive: boolean = false;
  serviceAdvisorNumberFormUrl!: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private contextDataService: ContextDataService,
    private translocoService: TranslocoService,
    private userProfileService: UserProfileService,
    private dateTimeService: DateTimeService,
    private formBuilder: FormBuilder,
    private tenantSettingsService: TenantSettingsService,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private _configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.pageConfigurator = new UserProfileConfigurator(
      this.tenantSettingsService.userProfileAvailableFields.split('|'),
      this.tenantSettingsService.userProfileCategorizedHourlyRatesDisplayColumns,
      this.tenantSettingsService.displayUserProfileBaseProductHourlyRates
    );

    this.reconfigureForm();

    const userProfile$ = this.userProfileService.loadUserProfile(
      this.contextDataService.data.tenant,
      this.contextDataService.data.user.serviceAdvisorId
    );
    const languages$ = this.userProfileService.getAvailableLanguages();
    const timezones$ = this.userProfileService.getTimezones();

    forkJoin([userProfile$, languages$, timezones$]).subscribe((result) => {
      const userProfile: UserProfile = result[0];
      this.languages = result[1];
      this.timezones = result[2];

      this.fillUserProfileForm(userProfile);
      this.isUserProfileLoaded = true;
    });

    this.isSelfServiceActive =
      this.tenantSettingsService.isDealerSelfServiceActive && !this.contextDataService.userInfo.isTireMember;

    this.serviceAdvisorNumberFormUrl = this._configurationService.serviceAdvisorNumberFormUrl.trim();
  }

  reconfigureForm() {
    if (!this.pageConfigurator.showTimeZoneId) {
      this.userProfileForm.removeControl('timeZoneId');
    }
    if (!this.pageConfigurator.showShouldMailSendForStatusUpdate) {
      this.userProfileForm.removeControl('shouldMailSendForStatusUpdate');
    }
    if (!this.pageConfigurator.showServiceAdvisorNumber) {
      this.userProfileForm.removeControl('serviceAdvisorNumber');
    }
    if (!this.pageConfigurator.showSalesNumber) {
      this.userProfileForm.removeControl('salesNumber');
    }
    if (!this.pageConfigurator.showDmsUserName) {
      this.userProfileForm.removeControl('dmsUserName');
    }
    if (!this.pageConfigurator.showPhoneNumber) {
      this.userProfileForm.removeControl('phoneNumber');
    }
  }

  fillUserProfileForm(userProfile: UserProfile) {
    this.userProfileForm.patchValue({
      language: userProfile.language,
      timeZoneId: userProfile.timeZoneId,
      shouldMailSendForStatusUpdate: userProfile.shouldMailSendForStatusUpdate,
      serviceAdvisorNumber: userProfile.serviceAdvisorNumber,
      salesNumber: userProfile.salesNumber,
      dmsUserName: userProfile.dmsUserName,
      phoneNumber: userProfile.phoneNumber
    });
  }

  onSubmit() {
    if (!this.userProfileForm.valid) {
      return;
    }

    this.errorList = [];
    this.submitLoading = true;

    const formBody = this.userProfileForm.getRawValue() as UserProfile;

    this.userProfileService.save(formBody).subscribe({
      next: () => {
        this.userProfileForm.markAsPristine();

        // Change client language once is successfully saved to the database
        const language =
          this.userProfileForm.value.language || this.contextDataService.data.languageInformation.defaultLanguage;
        this.translocoService.setActiveLang(language);
        this.dateTimeService.configureLocale(language);
        this.contextDataService.userLanguage = language;
        this.document.documentElement.lang = language;

        // reload user settings
        this.userService.load().subscribe();

        // this time we have to use selectTranslate instead of translate,
        // since after changing the activeLang it needs to reload the language before translating
        this.translocoService
          .selectTranslate('Account_UserProfile_SaveChangesSuccessful')
          .pipe(first())
          .subscribe({
            next: (message) => {
              this.snackbar.open(message);
            }
          });

        // reload user Profile
        this.userProfileService
          .loadUserProfile(this.contextDataService.data.tenant, this.contextDataService.data.user.serviceAdvisorId)
          .subscribe((userProfile: UserProfile) => this.fillUserProfileForm(userProfile));

        this.submitLoading = false;
      },
      error: (res: HttpErrorResponse) => {
        this.submitLoading = false;
        if (res.status !== HttpStatusCode.BadRequest) {
          return;
        }

        const validationReader = new ValidationReader(res.error);
        this.errorList = validationReader.values;

        validationReader.keys.forEach((key) => {
          this.userProfileForm.get(deCapitalizeFirstLetter(key))?.setErrors({ incorrect: true });
        });
      }
    });
  }
}
