<div class="mt-20" *transloco="let t">
  <div *ngFor="let typeKey of statusDetails.positionTypeKeys; let i = index">
    <h3 class="section-title" [class]="'ui-test--sdd-position-header-type-' + i">
      {{ t('Request_Positions_' + typeKey + '_Headline') }}
    </h3>

    <div *ngFor="let position of getPosition(typeKey); let index = index">
      <mat-card class="positions-card mb-15" [ngClass]="position.approvalState === 'NotApproved' ? 'chat-danger' : ''">
        <mat-card-content>
          <div class="flex-col">
            <div class="flex-row">
              <span class="position-index" [class]="'ui-test--sdd-position-span-product-type-' + index + '-type-' + i">
                {{ position.laborPositionIndex }}
              </span>
              <div class="mx-20 flex-grow-15" [class]="'ui-test--sdd-position-div-item-type-' + index + '-type-' + i">
                {{ position.itemTypeDisplay }}
              </div>
              <div
                class="flex flex-1"
                *ngIf="isEnabled(position, 'ExternalProductNumber')"
                [class]="'ui-test--sdd-position-div-external-product-number-' + index + '-type-' + i">
                {{ position.externalProductNumber }}
              </div>
            </div>

            <div class="flex-row justify-between content-none">
              <div
                class="flex-grow-33"
                *ngIf="isEnabled(position, 'ActivityTypeKey')"
                [class]="'ui-test--sdd-position-div-activity-type-name-' + index + '-type-' + i">
                {{ position.activityTypeName }}
              </div>
              <div class="flex-grow-33">
                <ng-container *ngIf="position.netFixedPrice > 0 || position.itemType === 'MobilitySparePart'">
                  <div class="flex-row" *ngIf="isEnabled(position, 'NetFixedPrice')">
                    <strong
                      class="flex flex-grow-50 justify-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-net-fixed-price-label-' + index + '-type-' + i">
                      {{ t('Common_{0}_NetFixedPrice' | stringFormat: position.itemType) }}
                    </strong>
                    <span
                      class="flex flex-1"
                      [class]="'ui-test--sdd-position-span-net-fixed-price-' + index + '-type-' + i">
                      {{ position.netFixedPrice | currencyOrDash }} {{ position.displayCurrencyUnit }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="position.netFixedPrice <= 0 || position.itemType === 'MobilitySparePart'">
                  <div class="flex-row" *ngIf="isEnabled(position, 'Quantity')">
                    <strong
                      class="flex flex-grow-50 justify-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-item-type-quantity-label-' + index + '-type-' + i">
                      {{ t('Common_{0}_Quantity' | stringFormat: position.itemType) }}
                    </strong>
                    <span
                      class="flex flex-1"
                      [class]="'ui-test--sdd-position-span-item-type-quantity-' + index + '-type-' + i">
                      {{ (position.quantity | numericFormat) + ' ' + (position.displayQuantityUnit ?
                      position.quantityUnitText:'') }}
                    </span>
                  </div>
                  <div
                    class="flex-row"
                    *ngIf="position.itemType !== 'MobilitySparePart' && isEnabled(position, 'UnitPrice')">
                    <strong
                      class="flex flex-grow-50 justify-end text-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-item-type-unit-price-label-' + index + '-type-' + i">
                      {{ t('Common_{0}_UnitPrice' | stringFormat: position.itemType) }}
                    </strong>
                    <span
                      class="flex flex-1"
                      [class]="'ui-test--sdd-position-span-item-type-unit-price-' + index + '-type-' + i">
                      {{ position.unitPrice | currencyOrDash }} {{ position.displayCurrencyUnit }}
                    </span>
                  </div>
                </ng-container>

                <!-- Display discount / goodwill -->
                <ng-container *ngIf="isEnabled(position, 'Goodwill')">
                  <div class="flex-row">
                    <strong
                      class="flex flex-grow-50 justify-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-goodwill-label-' + index + '-type-' + i">
                      {{ t('Common_{0}_Goodwill' | stringFormat: position.itemType) }}
                    </strong>
                    <span class="flex flex-1" [class]="'ui-test--sdd-position-span-goodwill-' + index + '-type-' + i">
                      {{ position.goodwill > 0 ? (position.goodwill | numericFormat) + ' %' : '–' }}
                    </span>
                  </div>
                </ng-container>

                <!-- Display discount / goodwill -->
                <ng-container *ngIf="isEnabled(position, 'MaterialGoodwill')">
                  <div class="flex-row">
                    <strong
                      class="flex flex-grow-50 justify-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-material-goodwill-label-' + index + '-type-' + i">
                      {{ t('StatusDetailsRequestPositionViewModel_MaterialGoodwill') }}
                    </strong>
                    <span
                      class="flex flex-1"
                      [class]="'ui-test--sdd-position-span-material-goodwill-' + index + '-type-' + i">
                      {{ position.materialGoodwill > 0 ? (position.materialGoodwill | numericFormat) + ' %' : '–' }}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="isEnabled(position, 'Discount')">
                  <div class="flex-row">
                    <strong
                      class="flex flex-grow-50 justify-end content-center pr-15"
                      [class]="'ui-test--sdd-position-strong-discount-label-' + index + '-type-' + i">
                      {{ t('StatusDetailsRequestPositionViewModel_Discount') }}
                    </strong>
                    <span class="flex flex-1" [class]="'ui-test--sdd-position-span-discount-' + index + '-type-' + i">
                      {{ position.discount > 0 ? (position.discount | numericFormat) + ' %' : '–' }}
                    </span>
                  </div>
                </ng-container>
              </div>
              <div class="flex-1">
                <div class="flex-row">
                  <strong
                    class="flex flex-grow-50 justify-end content-center pr-15"
                    [class]="'ui-test--sdd-position-strong-net-amount-label-' + index + '-type-' + i">
                    {{ t('Common_NetAmount') }}
                  </strong>
                  <span
                    class="flex flex-1 justify-end content-center"
                    [class]="'ui-test--sdd-position-span-net-amount-' + index + '-type-' + i">
                    {{ position.netAmount | currencyOrDash }} {{ position.displayCurrencyUnit }}
                  </span>
                </div>
                <div class="flex-row">
                  <strong
                    class="flex flex-grow-50 justify-end content-center pr-15"
                    [class]="'ui-test--sdd-position-strong-approval-status-label-' + index + '-type-' + i">
                    {{ t('Common_ApprovalStatus') }}
                  </strong>
                  <div class="justify-end content-center">
                    <request-state-label
                      [class]="'ui-test--sdd-position-request-state-label-approval-status-' + index + '-type-' + i"
                      *ngIf="position.approvalState; else noApprovalStatus"
                      [state]="position.approvalState"
                      [stateText]="t('Common_' + position.approvalState)"
                      stateUsage="StatusDetails"
                      class="flex-grow-35"></request-state-label>
                    <ng-template #noApprovalStatus>
                      <span>–</span>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-row" *ngIf="isEnabled(position, 'DamageType') && position.damageType">
              <strong
                class="flex flex-grow-20 justify-end content-center pr-15"
                [class]="'ui-test--sdd-position-strong-type-of-damage-label-' + index + '-type-' + i">
                {{ position.typeOfDamageTitle }}
              </strong>
              <span class="flex flex-1" [class]="'ui-test--sdd-position-strong-type-of-damage-' + index + '-type-' + i">
                {{ position.damageType.displayText }}
              </span>
            </div>

            <div class="flex-row" *ngIf="isEnabled(position, 'RentalReason') && position.rentalReason">
              <strong
                class="flex flex-grow-20 justify-end content-center pr-15"
                [class]="'ui-test--sdd-position-strong-reason-for-mobility-label-' + index + '-type-' + i">
                {{ t('Common_ReasonForMobility') }}
              </strong>
              <span
                class="flex flex-1"
                [class]="'ui-test--sdd-position-span-reason-for-mobility-' + index + '-type-' + i">
                {{ position.rentalReason.description }}
              </span>
            </div>

            <div class="flex-row" *ngIf="position.itemType === 'TirePosition'">
              <strong
                class="flex flex-grow-20 justify-end content-center pr-15"
                [class]="'ui-test--sdd-position-strong-tire-selection-label-' + index + '-type-' + i">
                {{ t('Common_TireSelection') }}
              </strong>
              <span
                class="flex flex-1"
                [class]="'ui-test--sdd-position-span-tire-selection-' + index + '-type-' + i"
                *ngIf="canDisplayTireText(position)">
                {{ getTireText(position) }}
              </span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer
          class="positions-footer"
          [ngClass]="position.approvalState === 'NotApproved' ? 'chat-danger' : ''"
          *ngIf="position.responseNotesByTimestamp.length > 0">
          <status-details-notes
            class="ui-test--sdd-position-status-details-notes-notes-' + index + '-type-' + i"
            [responseNotes]="position.responseNotesByTimestamp"
            [optionalItemsClass]="position.approvalState === 'NotApproved' ? 'chat-danger' : ''"></status-details-notes>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>

  <request-total-amounts [totalAmounts]="totalAmounts"></request-total-amounts>

  <div *ngIf="statusDetails.rentalCarNotes?.length">
    <h3 class="section-title ui-test--sdd-position-header-rental-car-notes">
      {{ t('StatusDetailsViewModel_RentalCarNotes') }}
    </h3>
    <status-details-notes
      class="ui-test--sdd-position-statusDetailsNotes-rental-car-notes"
      [responseNotes]="statusDetails.rentalCarNotes"></status-details-notes>
  </div>

  <div
    *ngIf="statusDetails.responseNotesByTimestamp?.length || statusDetails.annotation || statusDetails.textModuleText">
    <mat-divider class="my-20"></mat-divider>
    <h3 class="section-title ui-test--sdd-position-header-response-notes">
      {{ t('StatusDetailsViewModel_ResponseNotes') }}
    </h3>
    <status-details-notes
      class="ui-test--sdd-position-status-details-notes-rental-car-notes"
      [fullWidth]="false"
      [annotation]="statusDetails.annotation"
      [textModuleText]="statusDetails.textModuleText"
      [annotationDate]="statusDetails.createdDate"
      [responseNotes]="statusDetails.responseNotesByTimestamp"
      [optionalItemsClass]="requestNoteCssClass"></status-details-notes>
  </div>
</div>
