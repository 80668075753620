import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CanComponentDeactivate } from '@core/guards';
import { AlertDialogResult, AlertService, ErrorDialogOptions, ErrorHandlingService } from '@core/services';
import { ProductTypeIdentifier } from '@core/types/product-type-identifier.types';
import { ValidationReader } from '@core/validation-reader/validation-reader';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subscription, of, switchMap, take } from 'rxjs';
import { BreadcrumbService } from '../../components/layout/breadcrumb/breadcrumb.service';
import { RequestEditComponent } from './edit';
import { QuestionnaireComponent, QuestionnaireData } from './questionnaire';
import { RequestService } from './request.service';
import {
  ImportantInformation,
  RequestContent,
  RequestContentValidation,
  UnsavedChangesEventName
} from './request.types';
import { UpdateRequestContentContextResult } from './shared/general-information/mileage-dialog/mileage-dialog.types';
import { MileageUpdateLoadingComponent } from './shared/general-information/mileage-update-loading/mileage-update-loading.component';
import { ProcessBarComponent } from './shared/process-bar/process-bar.component';
import { ProcessBarStepData } from './shared/process-bar/process-bar.types';
import { RequestSummaryComponent } from './summary';
import { UnsavedChangesStateStore } from './unsaved-changes-state-store.service';

@Component({
  selector: 'request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild(ProcessBarComponent) processBarComponent!: ProcessBarComponent;
  @ViewChild(RequestEditComponent) requestEditComponent!: RequestEditComponent;
  @ViewChild(QuestionnaireComponent) questionnaireComponent!: QuestionnaireComponent;
  @ViewChild(RequestSummaryComponent) requestSummaryComponent!: RequestSummaryComponent;

  errorList!: string[];
  requestContent!: RequestContent;
  validationReader?: ValidationReader;
  loading = true;
  requestGuid!: string;
  showUnsavedChangesMessage: boolean = false;
  unsavedChanges$!: Subscription;
  apiSubscription$!: Subscription;
  guidv4_empty: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    private scroller: ViewportScroller,
    private router: Router,
    private requestService: RequestService,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
    private breadcrumbService: BreadcrumbService,
    private errorHandlingService: ErrorHandlingService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private unsavedChangesStateStore: UnsavedChangesStateStore
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hook methods
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.loadRoute();
    this.subscribeUnsavedChangesStateStore();
  }

  ngOnDestroy() {
    this.apiSubscription$.unsubscribe();
    this.unsavedChanges$.unsubscribe();
    this.disableUnsavedChangesMessage();
    this.breadcrumbService.resetToVin();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get selectedStep(): string {
    return this.processBarComponent?.steps?.find((step) => step.isActive === true)?.key ?? '';
  }

  get isEditStep(): boolean {
    return ['ServiceAndMaintenance', 'MotorInsurance', 'Mobility', 'Tires', 'Warranty', 'InsuranceRepair'].includes(
      this.selectedStep
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  changeStep(stepData: ProcessBarStepData) {
    if (stepData.requestGuid != null && stepData.requestGuid != '') {
      this.requestGuid = stepData.requestGuid;
    }
    this.errorList = [];
    this.validationReader = undefined;
    if (stepData.stepName === 'prev') {
      if (this.requestContent.versionId) {
        this.getRequestEditData(
          this.requestContent.versionId != this.guidv4_empty ? this.requestContent.versionId : this.requestGuid,
          undefined
        );
      }
      this.processBarComponent.previousStep();
    } else if (stepData.stepName === 'next') {
      this.processBarComponent.nextStep();
    }
  }

  setImportantInformation(information: ImportantInformation) {
    this.requestContent.userNote = information.note;
    this.requestContent.selectedTextModuleId = information.id;
  }

  setQuestionnaireData(questionnaireData: QuestionnaireData) {
    this.requestContent.answers = questionnaireData.answers;
    this.requestContent.questionnaireGroups = questionnaireData.questionnaireGroups;
  }

  displayValidation(errors: string[]) {
    this.errorList = errors;
    this.scroller.scrollToPosition([0, 0]);
  }

  public triggerContractSearch(mileage: number) {
    const dialogRef = this.dialog.open(MileageUpdateLoadingComponent, {
      width: '600px',
      height: '400px',
      disableClose: true
    });

    const oldMileage = this.requestContent.requestProcess.generalInformation.actualMileage;

    this.requestContent.requestProcess.generalInformation.actualMileage = mileage;
    this.requestContent.actualMileage = mileage;
    this.requestContent.requestProcess.requestIndex = 0;

    this.requestService.triggerContractSearch(this.requestContent).subscribe({
      next: (res: UpdateRequestContentContextResult) => {
        // if model is updated, refresh requestContent to ensure sync with database
        if (res.status === 'Updated') {
          this.unsavedChangesStateStore.addEventMessage('Saved');
          this.getRequestEditData(res.requestContent.versionId, this.requestContent.requestProcess.editProductType);
        } else {
          this.requestContent.requestProcess.generalInformation.actualMileage = oldMileage;
        }
        dialogRef.close();
      },
      error: () => {
        // reset mileage on error, to prevent a mismatch between contract and request
        this.requestContent.requestProcess.generalInformation.actualMileage = oldMileage;
        dialogRef.close();
      }
    });
  }

  public canDeactivate(): Observable<boolean> {
    return this.isNavigationAllowed();
  }

  public onStepChange(step: string): void {
    // Update route with new step and current timestamp
    this.router.navigate(['.'], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: 'merge',
      queryParams: {
        step: step.toLowerCase(),
        t: new Date().getTime()
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private Methods
  // -----------------------------------------------------------------------------------------------------

  private loadRoute() {
    const currentRoute = this.route.snapshot.routeConfig?.path;

    switch (currentRoute) {
      case 'create':
        this.createRequest();
        break;
      case 'edit':
        this.editRequest();
        break;
      default:
        this.router.navigate(['/not-found']);
    }
  }

  private createRequest() {
    this.route.queryParamMap.pipe(take(1)).subscribe((queryParams) => {
      this.apiSubscription$ = this.requestService.startNewRequest(queryParams).subscribe({
        next: (res: RequestContent) => {
          this.requestContent = res;
          // take the newly created version id as request guid
          this.requestGuid = res.versionId;
          this.setBreadcrumb();
          this.loading = false;
        },
        error: (res: HttpErrorResponse) => {
          this.loading = false;
          this.errorHandlingService.showErrorDialog(res, {
            errorMessage: this.translocoService.translate('Common_DmsResponse_RequestCreationError')
          } as ErrorDialogOptions);
        }
      });
    });
  }

  private editRequest() {
    this.route.queryParamMap.pipe(take(1)).subscribe((params: ParamMap) => {
      this.requestGuid = params.get('requestGuid') ?? '';
      const editProductType = params.has('editProductType')
        ? (params.get('editProductType') as ProductTypeIdentifier)
        : undefined;
      this.getRequestEditData(this.requestGuid, editProductType);
    });
  }

  private getRequestEditData(requestGuid: string, editProductType: ProductTypeIdentifier | undefined) {
    this.loading = true;
    this.apiSubscription$ = this.requestService.getRequestEditData(requestGuid, editProductType).subscribe({
      next: (res: RequestContent) => {
        this.requestContent = res;
        this.requestGuid = res.versionId;

        this.setBreadcrumb();
        this.loading = false;
      },
      error: (res: HttpErrorResponse) => {
        this.loading = false;
        if (res.status === HttpStatusCode.UnprocessableEntity) {
          const response: RequestContentValidation = res.error;
          this.requestContent = response.content;
          this.requestGuid = response.content.versionId;
          const validationReader = new ValidationReader(response.error);
          this.displayValidation(validationReader.values);
          this.validationReader = validationReader;
        }

        if (res.status !== HttpStatusCode.UnprocessableEntity) {
          this.errorHandlingService.showErrorDialog(res, {
            errorMessage: this.translocoService.translate('Common_DmsResponse_ContractNotFound')
          } as ErrorDialogOptions);
        }
      }
    });
  }

  private setBreadcrumb() {
    this.breadcrumbService.breadcrumb = {
      contractNumber: this.requestContent.requestProcess.contractNumber,
      licensePlateNumber: this.requestContent.requestProcess.licensePlateNumber,
      productType: this.requestContent.requestProcess.productType,
      requestId: this.requestContent.requestIdForCustomer,
      vehicleIdentificationNumber: this.requestContent.requestProcess.generalInformation.vehicleIdentificationNumber
    };
  }

  private subscribeUnsavedChangesStateStore() {
    this.unsavedChanges$ = this.unsavedChangesStateStore.unsavedChangesEventMessages.subscribe(
      (message: UnsavedChangesEventName) => {
        if (!this.showUnsavedChangesMessage && message == 'Dirty') {
          this.activateUnsavedChangesMessage();
          return;
        }
        if (this.showUnsavedChangesMessage && (message == 'Saved' || message == 'Undo')) {
          this.disableUnsavedChangesMessage();
        }
      }
    );
  }

  private activateUnsavedChangesMessage() {
    this.showUnsavedChangesMessage = true;
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  private disableUnsavedChangesMessage() {
    this.showUnsavedChangesMessage = false;
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  private beforeUnload(event: BeforeUnloadEvent) {
    const confirmationMessage = 'o/';
    event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage; // Gecko, WebKit, Chrome <34
  }

  private isNavigationAllowed(): Observable<boolean> {
    if (!this.showUnsavedChangesMessage) {
      return of(true);
    }

    const confirmUndoRequestDialogRef = this.alertService.open({
      message: this.translocoService.translate('Request_Edit_UndoChanges'),
      actions: {
        confirm: {
          label: this.translocoService.translate('Request_Edit_LeaveWithSave')
        },
        additionalButton: {
          show: true,
          label: this.translocoService.translate('Request_Edit_LeaveWithoutSave')
        },
        cancel: {
          show: true,
          label: this.translocoService.translate('Common_No')
        }
      }
    });

    return confirmUndoRequestDialogRef.afterClosed().pipe(
      switchMap((result: AlertDialogResult) => {
        if (result === 'confirmed') {
          if (!this.canSaveCurrentStep()) {
            return of(false);
          }
          this.saveCurrentStep();
        }
        return of(result === 'confirmed' || result === 'additional');
      })
    );
  }

  private canSaveCurrentStep(): boolean {
    if (this.selectedStep === 'Summary') {
      return !this.requestSummaryComponent.hasNotUploadedFiles();
    }

    return true;
  }

  private saveCurrentStep(): void {
    if (this.isEditStep) {
      this.requestEditComponent.save();
      return;
    }

    if (this.selectedStep == 'Questionnaires') {
      this.questionnaireComponent.save();
      return;
    }

    if (this.selectedStep === 'Summary') {
      this.requestSummaryComponent.save();
    }
  }
}
