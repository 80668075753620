<ng-container *transloco="let t">
  <div class="mt-20">
    <validation-summary [errorList]="errorList"></validation-summary>
  </div>

  <div class="flex-row justify-between content-start gap-10">
    <form [formGroup]="userProfileForm" (ngSubmit)="onSubmit()" class="flex-grow-50">
      <mat-card>
        <mat-card-title class="ui-test--user-profile-mat-card-title-user-profile-title">
          {{ t('Account_UserProfile_Title') }}
        </mat-card-title>
        <mat-card-content class="mt-20">
          <div
            class="flex-col flex-grow-100 justify-around content-center align-center"
            *ngIf="isUserProfileLoaded; else userProfileLoading">
            <mat-form-field class="language-picker ui-test--user-profile-mat-form-field-language">
              <mat-label class="ui-test--user-profile-mat-label-language">
                {{ t('AccountUserProfileViewModel_Language') }}
              </mat-label>
              <mat-select formControlName="language" class="ui-test--user-profile-mat-select-language">
                <mat-option value="" class="none ui-test--user-profile-mat-label-language-none">
                  {{ t('Common_None') }}
                </mat-option>
                <mat-option
                  [value]="option"
                  [class]="[option, 'ui-test--user-profile-mat-label-' + option]"
                  *ngFor="let option of languages">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="pageConfigurator.showTimeZoneId"
              class="ui-test--user-profile-mat-form-field-time-zone">
              <mat-label class="ui-test--user-profile-mat-label-time-zone">
                {{ t('AccountUserProfileViewModel_TimeZoneId') }}
              </mat-label>
              <mat-select formControlName="timeZoneId" class="time-zone ui-test--user-profile-mat-select-time-zone">
                <mat-option
                  [value]="option.value"
                  *ngFor="let option of timezones"
                  [class]="'ui-test--user-profile-mat-option-' + option.text">
                  {{ option.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="pageConfigurator.showShouldMailSendForStatusUpdate"
              class="ui-test--user-profile-mat-form-field-should-mail-send-for-status-update">
              <mat-label class="ui-test--user-profile-mat-label-should-mail-send-for-status-update">
                {{ t('AccountUserProfileViewModel_ShouldMailSendForStatusUpdate') }}
              </mat-label>
              <mat-select
                formControlName="shouldMailSendForStatusUpdate"
                class="send-mail ui-test--user-profile-mat-select-should-mail-send-for-status-update">
                <mat-option [value]="true" class="ui-test--user-profile-mat-option-yes">
                  <mat-icon class="ui-test--user-profile-mat-icon-yes">notifications</mat-icon>
                  {{ t('Account_LogOn_MailSend') }}
                </mat-option>
                <mat-option [value]="false" class="ui-test--user-profile-mat-option-no">
                  <mat-icon class="ui-test--user-profile-mat-icon-no">notifications_off</mat-icon>
                  {{ t('Account_LogOn_NoMailSend') }}
                </mat-option>

                <mat-select-trigger>
                  {{ userProfileForm.get('shouldMailSendForStatusUpdate')?.value ? t('Account_LogOn_MailSend') :
                  t('Account_LogOn_NoMailSend') }}
                </mat-select-trigger>
              </mat-select>
            </mat-form-field>

            <input-with-validation
              class="long-error ui-test--user-profile-input-with-validation-service-advisor-number"
              *ngIf="pageConfigurator.showServiceAdvisorNumber && userProfileForm.controls.serviceAdvisorNumber"
              resourceKeyPrefix="AccountUserProfileViewModel"
              name="serviceAdvisorNumber"
              [control]="userProfileForm.controls.serviceAdvisorNumber"></input-with-validation>
            <div class="url-item ui-test--user-profile-div-service-advisor-number-form-url">
              <a
                class="link-item ui-test--user-profile-link-service-advisor-number-form-url"
                [href]="serviceAdvisorNumberFormUrl"
                target="_blank"
                rel="noopener">
                {{ t('AccountUserProfileViewModel_ServiceAdvisorNumberFormUrl') }}
              </a>
            </div>

            <input-with-validation
              class="ui-test--user-profile-input-with-validation-sales-number"
              *ngIf="pageConfigurator.showSalesNumber && userProfileForm.controls.salesNumber"
              resourceKeyPrefix="AccountUserProfileViewModel"
              name="salesNumber"
              [control]="userProfileForm.controls.salesNumber"></input-with-validation>

            <input-with-validation
              class="ui-test--user-profile-input-with-validation-dms-user-name"
              *ngIf="pageConfigurator.showDmsUserName && userProfileForm.controls.dmsUserName"
              resourceKeyPrefix="AccountUserProfileViewModel"
              name="dmsUserName"
              [control]="userProfileForm.controls.dmsUserName"></input-with-validation>

            <input-with-validation
              class="ui-test--user-profile-input-with-validation-phone-number"
              *ngIf="pageConfigurator.showPhoneNumber && userProfileForm.controls.phoneNumber"
              resourceKeyPrefix="AccountUserProfileViewModel"
              name="phoneNumber"
              type="tel"
              [control]="userProfileForm.controls.phoneNumber"></input-with-validation>
          </div>
          <ng-template #userProfileLoading>
            <div class="spinner"></div>
          </ng-template>
        </mat-card-content>
        <mat-card-actions align="end" *ngIf="isUserProfileLoaded">
          <button
            class="ui-test--user-profile-button-submit"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="submitLoading || !userProfileForm.valid"
            [class.button-spinner]="submitLoading">
            {{ t('Common_Button_SaveChanges') }}
          </button>
        </mat-card-actions>
      </mat-card>
    </form>

    <guidelines-panel *ngIf="pageConfigurator.showGuidelinesPanel" class="flex-grow-50"></guidelines-panel>
  </div>

  <self-service class="flex-row mt-20" *ngIf="isSelfServiceActive"></self-service>

  <categorized-hourly-rates
    *ngIf="pageConfigurator.userProfileCategorizedHourlyRatesDisplayColumns"
    class="flex-row mt-20"></categorized-hourly-rates>

  <base-product-hourly-rates
    *ngIf="pageConfigurator.displayUserProfileBaseProductHourlyRates"
    class="flex-row mt-20"></base-product-hourly-rates>
</ng-container>
